<template>
    <div class="max-width order-detail margin-40">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="height: 55px;" class="flex-r-center">
            <el-breadcrumb-item :to="{ path: '/allCate' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/myOrder' }">我的订单</el-breadcrumb-item>
            <el-breadcrumb-item>订单详情</el-breadcrumb-item>

        </el-breadcrumb>
        <div class="white-back paddding-20">
            <h1 class="fs-20 " style="padding-top: 20px;">订单详情</h1>
            <Step :steps="steps" :active="active"></Step>
        </div>
        <div class="paddding-20 white-back info">
            <div class="margin-40">
                <div class="bg paddding-20">

                    <p> 订单号：{{info.order_num}}</p>
                    <p class="paddding-20"> 订单状态：<span class="r-f">{{info.order_status_msg}}</span></p>
                    <p>下单时间：{{$fomartDate(info.createtime,10)}}</p>
                </div>
                <div class="paddding-20">
                    <div v-if="info.status_types==1" style="padding: 20px 0;">
                        <h2>自提点</h2>
                        <div>
                            <div class="flex" style="margin: 20px 0;">
                                <p> {{info.addresss.name}}</p>
                                <p class="paddding-20"> {{info.addresss.address}}</p>
                            </div>

                            <div>
                                <span>营业时间：{{info.addresss.addressasd}}</span>
                                <span class="paddding-20">联系电话：{{info.addresss.phone}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h2 style="margin:20px 0;">物流信息</h2>
                        <div v-if="wuliu">
                            <div v-if="wuliu.status==2" style="padding-bottom: 20px;">

                                <img :src="domain+item" mode="aspectFill" class="img"
                                     style="width: 100%;height: 300px;" v-for="(item,index) in wuliu.data.split(',')"/>
                            </div>
                            <div v-else>

                                <div v-for="item in wuliu.data" style="border-top: 1px solid #eee;padding-top: 20px;">
                                    <div v-if="!item.nu">{{'你所查找的快递'+item.message}}</div>
                                    <div v-else>
                                        <div style="padding-bottom: 20px;">
                                            <span>快递:{{wuliu.kuaidi}}</span>
                                            <span class="paddding-20">快递单号:{{item.nu}}</span>
                                        </div>
                                        <div v-for="sitem in item.data" style="padding-bottom: 20px;">
                                            <span>{{sitem.time}}</span> <span
                                                class="paddding-20">{{sitem.context}}</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div v-else style="padding-bottom: 20px;">
                            暂时没有物流信息~
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <div class="">
                    <h2 class="bg  paddding-20">订单信息</h2>

                    <div class=" order-info paddding-20">
                        <div v-if="info.status_types==2">
                            <p>收货人信息</p>
                            <div v-if="info.address">
                                <span>{{info.addresss.consignee}}</span>
                                <span class="paddding-20">{{info.addresss.phone}}</span>
                                <span>	{{info.addresss.province_name+info.addresss.city_name+info.addresss.area_name+info.addresss.address}}</span>
                            </div>

                        </div>
                        <div v-else>
                            <p>提货人</p>
                            <div>
                                <span>{{info.shou_name}}</span>
                                <span class="paddding-20">{{info.shou_phone}}</span>
                            </div>
                        </div>

                        <div>
                            <p>支付方式及配送信息</p>
                            <div class="flex">
                                <p>支付方式：
                                    <span v-if="/wxpay|native/.test(info.pay_type)">微信支付</span>
                                    <span v-if="info.pay_type=='enterprise'">额度支付</span>
                                    <span v-if="info.pay_type=='alipay'">支付宝支付</span>
                                </p>
                                <span class="paddding-20">配送信息：{{info.status_types==1?'自提':'配送'}}</span>
                            </div>
                        </div>
                        <div v-if="info.tax_param">
                            <p>发票信息</p>
                            <div>
                                <p>发票类型：企业单位</p>
                                <p style="margin: 20px 0;">发票抬头：{{info.tax_param.companyName}}</p>
                                <p>企业税号：{{info.tax_param.taxNumber}}</p>
                            </div>
                        </div>
                        <div>
                            <p>订单备注</p>
                            <div>{{info.remark?info.remark:''}}</div>
                        </div>
                    </div>

                </div>
                <div class="good-info">
                    <h2>商品信息</h2>
                    <el-table

                            :data="goods"
                            style="margin:0 20px;width: auto;">
                        <el-table-column
                                label="商品"
                                width="480">
                            <template slot-scope="scope">
                                <div class="flex-r-center">
                                    <img :src="scope.row.goods_img" alt=""
                                         style="width: 120px;height: 120px;margin-right: 20px;">
                                    <span v-if="scope.row.brand.name">【{{scope.row.brand.name}}】</span>
                                    <span>{{scope.row.goods_name}}</span>
                                    <span class="g-f paddding-20">{{scope.row.sku_guige}}</span>
                                </div>


                            </template>
                        </el-table-column>
                        <el-table-column
                                label="单价（元）"
                        >
                            <template slot-scope="scope">
                                <span class="bold">￥{{scope.row.market_price}}</span>

                            </template>
                        </el-table-column>
                        <el-table-column
                                label="数量"
                                prop="buy_num"
                        >

                        </el-table-column>
                        <el-table-column
                                label="小计（元）"
                        >
                            <template slot-scope="scope">
                                <span class="bold">￥{{$fixed(scope.row.market_price*scope.row.buy_num)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="info.order_status==5"
                                label="操作"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.pingjia==2" >已评价</span>
                                <span v-else class="pointer" @click="goEvalute(scope.row,0)">去评价</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="padding: 20px 20px 0 20px;" class="right">
                        实付金额（含运费{{info.freight}}元）：
                        <span class="r-f fs-18 bold">￥{{$fixed(info.total_price+parseFloat(info.freight))}}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Step from "@/components/Step";
    import {order_detail, oIndexwuliu} from '@/request/api';
    import {domain} from "@/request/utils";

    export default {
        name: "orderDetail",
        components: {
            Step
        },
        data() {
            return {
                domain,
                active: 2,
                steps: [
                    {title: '提交订单'},
                    {title: '等待付款'},
                    {title: '卖家发货'},
                    {title: '确认收货'},
                    {title: '评价'},
                ],
                goods: [],
                info: {},
                id: this.$route.query.id,
                wuliu: {}
            }
        },
        methods: {
            goEvalute(item, index) {
                let obj={
                    details:[item]
                }
                localStorage.setItem('evaluateGoods', JSON.stringify(obj))
                const url = this.$router.resolve({path: '/evaluate', query: {index}})
                window.open(url.href, '_blank');

            },
            async getInfo() {
                let res = await order_detail({order_id: this.id});
                if (res.data.tax_param) {
                    res.data.tax_param = JSON.parse(res.data.tax_param)
                }
                this.info = res.data;
                if (res.data.order_status == 4) {
                    this.active = 2;
                }
                if (res.data.order_status == 5) {


                    let pingjia = res.data.goods.some((item) => {
                        return item.pingjia == 2
                    })
                    
                  
                    this.active = pingjia ? 4 : 3;
                   
                   
                }
                if (res.data.order_status == 1) {
                    this.active = 1;
                }
                if (res.data.order_status == 2) {
                    this.active = 0;
                }
                if (res.data.order_status == 6) {
                    this.active = -1;
                }
                this.goods = res.data.goods;
            },
            async ckwl() {
                let res = await oIndexwuliu({order_id: this.id});
                this.wuliu = res.data;

            }
        },
        created() {
            this.getInfo();
            this.ckwl()
        }
    }
</script>

<style scoped lang="scss">

    .order-detail {
        /deep/ .el-table th.is-leaf, .el-table td {
            background: #eee;
        }

        h1 {
            font-weight: normal;
        }


        .order-info > div {
            border-bottom: 1px solid #eee;
            padding-bottom: 20px;
        }

        .order-info > div > p {
            margin: 20px 0;
        }

        .bg {
            height: 40px;
            background: #f6f6f6;
            display: flex;
            align-items: center;
        }

        .good-info {
            h2 {
                height: 70px;
                line-height: 70px;
                padding: 0 20px;

            }

            padding-bottom: 20px;
        }

        h2 {
            font-weight: normal;

        }

        .info {
            padding-bottom: 20px;
        }

        .info > div {
            border: 1px solid #eee;

        }
    }
</style>
