<template>
    <div class="step">
        <div v-for="(item,index) in steps" :key="index" class="all1">
            <div class="flex-r-center flex-column " :class="index<=active?'active':'inactive'">

                <span class="num">{{index+1}}</span>
                <span class="title flex-r-center">{{item.title}}</span>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            steps: Array,
            active: Number
        }
    }
</script>

<style scoped lang="scss">
    .step {
        height: 140px;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-around;

        .num {
            display: inline-block;
            height: 31px;
            width: 31px;
            border-radius: 50%;
            line-height: 31px;
            text-align: center;
            color: white;
            position: relative;
        }

        .all1:nth-last-child(n+2) .num:after {
            position: absolute;
            content: ' ';
            background: #ccc;
            width: 150px;
            height: 2px;
            display: inline-block;
            left: 60px;
            top: 15px;
        }

        .active {
            .num {
                background: #386FF0;


            }

            .title {
                color: #386FF0;
            }
        }

        .inactive .num {
            background: #DEDEDE;
        }

        .title {
            margin: 17px 17px;
            color: #999;
        }

        /*& > div:nth-last-child(n+2) .title:after {*/
        /*    content: ' ';*/
        /*    background: #ccc;*/
        /*    width: 96px;*/
        /*    height: 2px;*/
        /*    display: inline-block;*/
        /*    margin-left: 17px;*/
        /*}*/
    }
</style>
